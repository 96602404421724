import * as React from "react";
import Navigation from "../components/Navigation";
import WritingAndResearchTemplatesSeo from "../components/SEO/WritingAndResearchTemplatesSeo";
import "../styles/style.css";

const WritingAndResearchTemplates = () => {
  return (
    <main>
      <div className="content">
        <WritingAndResearchTemplatesSeo></WritingAndResearchTemplatesSeo>
        <Navigation></Navigation>
        <div className="about__section-1">
          <div>
            <div className="glass">
              <h1>Writing and Research Templates</h1>
            </div>
          </div>
          <div className="lat__bullet-circle--container">
            <div>
              <div className="bullet-circle"></div>
              <p>
                Here is a treasure trove of templates in writing and research
              </p>
            </div>
            <div>
              <div className="bullet-circle"></div>
              <p>Research well to save money, time and energy</p>
            </div>
          </div>
        </div>
        <div className="lat__advice-grid">
          <div class="glass mobile-span">
            <div className="resources">
              <i class="fas fa-download"></i>
            </div>
            <div className="lat__panel-title">Writing Resources</div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                <a href="https://www.dropbox.com/scl/fi/ouz41hkce8nlqpmk8ut4n/UX-Writing-Examples.xlsx?dl=0&rlkey=n6vfruf0dshkvm0d37i8avalu">
                  UX Writing Examples
                </a>
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                <a href="www.strings.design/ux-writing-glossary">
                  UX Writing Glossary
                </a>
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                <a href="https://xd.adobe.com/ideas/process/information-architecture/ux-writing-guidelines/">
                  Adobe's UX Writing Guidelines
                </a>
              </div>
            </div>
          </div>
          <div class="glass mobile-span">
            <div className="resources">
              <i class="fas fa-download"></i>
            </div>
            <div className="lat__panel-title">Research Resources</div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                <a href="https://uxdesign.cc/user-research-plan-template-d7e263ebee79">
                  User Research Plan Template
                </a>
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                <a href="https://www.uxbooth.com/articles/complete-beginners-guide-to-design-research/">
                  Beginners guide to design research
                </a>
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                <a href="https://www.dropbox.com/scl/fi/c4xroxisdpqafxrggnj96/User-Persona-Research-Data-Collection-Template.gsheet?dl=0&rlkey=cdtn4h337vg3gytnignxj6i0x">
                  User Persona Research Data Collection Template
                </a>
              </div>
            </div>
          </div>
          <div class="glass mobile-span">
            <div className="tips">
              <i class="fas fa-question"></i>
            </div>
            <div className="lat__panel-title">Tips</div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                As you learn common words, create a glossary
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Struggling for ideas or inspiration? Create a moodboard!
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Create a user story- board based on the goals of the project.
                After research, update it to enhance the final user experience
              </div>
            </div>
          </div>
          <div class="glass span-2">
            <span>CTA (Call to Action) :</span> a piece of content intended to
            induce a viewer, reader, or listener to perform a specific act,
            typically taking the form of an instruction or directive.
          </div>
          <div class="tips">
            <i class="fas fa-question"></i>
          </div>
          <div class="glass span-2">
            <span>Is Microcopy UX writing? :</span> No. This is like calling UI
            design: UX design. They are two different professions. In short,
            Micro- copy is the short text used on components such as buttons. UX
            has much more involved, such as customer-facing communication at
            each step of a customer journey.
          </div>
          <div class="tips">
            <i class="fas fa-question"></i>
          </div>
          <div className="full-width"></div>
        </div>
      </div>
    </main>
  );
};

export default WritingAndResearchTemplates;
